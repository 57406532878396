.neos-backend .ck-content .table table th, th,
.neos-backend .ck-content .table table th > p, th > p
{
	@apply font-medium;
}

.neos-backend .ck-content .table table th,
.neos-backend .ck-content .table table td,
td,
th {
    vertical-align: top;
    padding-right: 20px;
    text-align: left;
}

figure.table table {
    @apply mb-30;
}

#main table.generic-table,
figure.table table {
	width: 100%;
    max-width: 960px;

	border-top: 1px solid currentColor;
	border-bottom: 1px solid currentColor;

	tr {
		@apply mb-30;

		&:not(:last-child) {
			border-bottom: 1px solid currentColor;

		}

		th, td {
			padding-top: 1em;
			padding-bottom: 1em;

			padding-left: 0;

			padding-right: 1.75em;

			&:last-child {
				padding-right: 0;

			}

			text-align: left;

		}

		th {
			@apply font-medium;

		}

		td {

		}

	}

}
