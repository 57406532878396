/**
 * Product Detail Page
 *
 */

.product-detail-page {

    &.diy {

        /* no background shapes for diy PDP */
        .background-shape-container {
            @apply hidden;
        }

        footer.section.alternative-background::before {
            background: none;
        }

        .product-detail--hero {
            //background-color: var(--warmgray);
            //@apply text-white;
            @apply bg-warmgray;
            @apply py-60;

            @screen md {
                @apply pt-30;
            }

            @screen lg {
                @apply pb-90;
            }

            .product-breadcrumb {
                @apply mb-30;

                @screen md {
                    @apply mb-60;
                }
            }
        }

        @screen md {
            .product-feature-list {
                font-size: 18px;
            }
        }
    }
}
