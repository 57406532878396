/**
 * Galleries
 *
 */

.gallery {
	position: relative;

	height: 100vh;
	visibility: hidden;

	&__navigation {
		position: absolute;
		z-index: 1;

		@apply inset-0;

		pointer-events: none;

		&__arrow {
			pointer-events: auto;

			width: 60px;
			height: 60px;

			background: 50% no-repeat;

			cursor: pointer;

			opacity: .75;

			&:hover {
				opacity: 1;

			}

			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&--left {
				background-image: url("#{$img-root}/ui/gallery-arrow-left.svg");
				left: 30px;

			}

			&--right {
				background-image: url("#{$img-root}/ui/gallery-arrow-right.svg");
				right: 30px;

			}
			
			display: none;
			
			@screen md {
				display: block;
				
			}
			
		}
		
		&__progress-bar {
			@extend %progress-bar;
			
			&-item {
				@extend %progress-bar-item;
				
			}

		}

	}

	&__slide-container {
		position: relative;

		min-height: 150px;
		overflow: hidden;

		body.neos-backend & {
			overflow: visible;

		}

		> * {
			position: absolute;

		}

	}
	
	&.rte-gallery {
		.gallery__slide-container {
			body.neos-backend & {
				overflow: hidden;
				
			}
			
		}
		
	}
	
	&__slide {
		position: absolute;

		&--image {
			// overflow: hidden;

			figure {
				margin: 0;

			}

			picture {
				position: absolute;

			}

		}

	}

}

.gallery__caption-footer {
	@extend .container;

	margin-top: 15px;
	margin-bottom: 0;
	
	position: relative;
	
	> * {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;

	}

}

body.neos-backend  {
	.gallery__caption-footer {
		// background: rgba(green, .5);
		pointer-events: none;

	}

	.gallery__slide--image {
		figcaption {
			position: absolute;
			z-index: 2;

			top: auto;
			bottom: 0;
			transform: translateY(100%);
			width: 100%;

			> div {
				@extend %container-width;
				margin-left: auto;
				margin-right: auto;

				margin-top: 15px;

			}

			background: rgba(255, 255, 255, .75);
			margin-top: 0;
			margin-bottom: 0;


		}

	}

}

/*
	Teaser galleries
*/

%teaser-with-squared-content {
	> div:first-child {
		position: relative;
		z-index: 1;
		
		+ div {
			width: 100%;
			aspect-ratio: 1/1;
			
			padding-top: 74px;
			margin-top: -74px + 5px;
			
			@apply bg-warmgray;
			
			@screen xl {
				.text-link {
					margin-bottom: -8px;
					
				}
				
			}
			
		}
		
	}
	
}

.teaser-gallery {
	position: relative;

	padding-bottom: 65px;
	
	@screen md {
		margin-bottom: 10px;
		
	}
	@screen xl {
		margin-bottom: 20px;
		
	}
	
	.row {
		justify-content: center;

	}

	.column {
		position: relative;

	}
	
    .product-teaser-slider {
		width: 100%;
		max-width: 250px;
		margin-left: auto;
		margin-right: auto;
		
		@screen sm {
			max-width: none;
			
		}
		
		@extend %teaser-with-squared-content;
		
		img {
			aspect-ratio: 1/1;
			object-fit: contain;
			
		}
		
    }
	
	.gallery__navigation__arrow {
        
		&--left {
			background-image: url("#{$img-root}/ui/teaser-gallery-arrow-left.svg");

			left: -60px;

			@screen sm {
				left: -10px;

			}

			@media (min-width: 640px) {
				left: -34px;

			}

			@screen md {
				left: -60px;

			}

			@screen lg {
				left: -20px;

			}

			@media (min-width: 1112px) {
				left: -60px;

			}

		}

		&--right {
			background-image: url("#{$img-root}/ui/teaser-gallery-arrow-right.svg");

			right: -60px;

			@screen sm {
				right: -10px;

			}

			@media (min-width: 640px) {
				right: -34px;

			}

			@screen md {
				right: -60px;

			}

			@screen lg {
				right: -20px;

			}

			@media (min-width: 1112px) {
				right: -60px;

			}

		}

		&.disabled {
			pointer-events: none;
			opacity: .2;

		}

	}

	&--download {
		.gallery__navigation__arrow {
			&--left {
				background-image: url("#{$img-root}/ui/gallery-arrow-left.svg");
				left: -40px;

				@screen sm {
					left: -10px;

				}
				@media (min-width: 640px) {
					left: -34px;

				}
				@screen md {
					left: -60px;

				}
				@screen lg {
					left: -20px;

				}
				@media (min-width: 1112px) {
					left: -60px;

				}
			}
			&--right {
				background-image: url("#{$img-root}/ui/gallery-arrow-right.svg");
				right: -40px;

				@screen sm {
					right: -10px;

				}
				@media (min-width: 640px) {
					right: -34px;

				}
				@screen md {
					right: -60px;

				}
				@screen lg {
					right: -20px;

				}
				@media (min-width: 1112px) {
					right: -60px;

				}
			}
		}
	}

	.gallery__slide-container {
		// border: 1px solid green;
		overflow: hidden;

		.gallery__slide--teaser {
			// border: 2px solid red;

		}

	}

	.gallery__slide--teaser__product {
		img {
			position: relative;
			z-index: 1;
			
			width: 90%;
			margin-left: auto;
			margin-right: 2.5%;
			
			@screen md {
				width: 85%;
				margin-right: 2.5%;
				
			}
			@screen lg {
				margin-right: 2.5%;
				
			}
			@screen xl {
				margin-right: 5%;
				
			}
			
		}
		
		.bg-white {
			padding-top: 0 !important;

			&:before {
				content: " ";
				display: block;

				position: absolute;
				left: 0;
				top: 20%;
				width: 100%;

				padding-bottom: 100%;

				@apply bg-white;
				z-index: -1;

			}

			@screen lg {
				position: relative;

				&:before {
					top: 0;
					bottom: auto;

					transform: translateY(-60%);

				}

			}

		}

		.download-list {
			background: transparent;
			position: relative;

			.text-link {
				&:after {
					content: none;

				}

			}
			
			@extend %teaser-with-squared-content;
			
			> div:first-child {
				min-height: 50px - 8px;
				
				+ div {
					margin-top: -50px + 8px;
					
				}
				
			}
			
		}
		
	}

    // smaller product teasers eg product overview page
    .teaser-gallery__teaser--small {

        .product-teaser-slider {
			height: auto;
			
            h3 {
                font-size: 18px;
            }

            .text-link::after,
            .text-link-highlight::after {
                height: 0.65em;
            }
            
            > div:first-child + div {
				padding-top: 35px;
				margin-top: -35px;
				
            }
            
        }

    }

}

.gallery__navigation__progress-bar {
	position: absolute;

	bottom: 5px - 60px;
	
}

.gallery__navigation__progress-bar-item:after {
	background-color: #a6a6a6;

}

[data-class-name=GallerySection],
[data-class-name=SliderProductGallerySection] {
	.gallery__navigation__arrow {
		display: none;
		
	}
	
	&.gallery--navigation-arrows-shown {
		.gallery__navigation__arrow {
			@screen md {
				display: block;
				
			}
			
		}
		
	}
	
	.gallery__navigation__progress-bar {
		display: none;
		
		position: static;
		margin-top: 55px;
		
	}
	
	&.gallery--progress-dots-shown {
		.gallery__navigation__progress-bar {
			display: flex;
			
		}
		
	}
	
}

/*
	XLTeaserSliderSection
*/

[data-class-name=XLTeaserSliderSection] {
	.gallery__navigation {
		z-index: 2;
		
		@extend .unselectable;

		&__arrow {
			top: 15px;

			@media (min-width: 1200px) {
				top: 50%;

			}

			&--left {
				left: 0;

				@media (min-width: 1200px) {
					left: -100px;

				}
				@screen xl {
					left: 0;

				}

				background-image: url("#{$img-root}/ui/gallery-arrow-grey-left.svg");

			}
			&--right {
				right: 0;

				@media (min-width: 1200px) {
					right: -100px;

				}
				@screen xl {
					right: 0;

				}

				background-image: url("#{$img-root}/ui/gallery-arrow-grey-right.svg");

			}

		}

		&__progress-bar {
			top: 0;
			bottom: auto;

		}

	}

	.gallery__slide-container {
		overflow: visible;

		margin-top: 45px + 20px;
		margin-bottom: 20px;
		
		.gallery__navigation {
			margin-top: -45px - 20px;
			
		}
		
		@screen md {
			margin-top: 60px + 20px + 20px;
			
			.gallery__navigation {
				margin-top: -60px - 20px;
				
			}
			
		}
		
		@screen lg {
			margin-bottom: 0;
			
		}
		
		@media (min-width: 1200px) {
			margin-top: 60px + 20px;
			
		}
		
	}
	
	.gallery__slide {
		background: #ffffff;
		
		@screen lg {
			.justify-center, .self-center {
				height: 100%;
				
			}
			
		}
		
		> .row {
			margin-top: 0;
			margin-bottom: 0;

			> .column {
				&:first-child {
					padding-top: 0;

				}
				&:not(:first-child) {
					padding-bottom: 0;

				}

			}

			@screen lg {
				> .column {
					padding-top: 0;
					padding-bottom: 0;

				}

			}

		}

	}

}

/*
	RTE Slider
*/

.rte-gallery {
	position: relative;
	
	padding-bottom: 65px;
	
	.row {
		justify-content: center;
		
	}
	
	.rich-text {
		h2, h3, h4 {
			margin-bottom: 1.25rem;
			
		}
		
	}
	
	.gallery__navigation__progress-bar {
		margin-top: 45px;
		
	}
	
	&.gallery-primary-color {
		.gallery__navigation__arrow {
			border-radius: 50%;
			
			@apply bg-green;
			
			opacity: 1;
			
			&:hover {
				@apply bg-black;
				
			}
			
			&--left {
				background-image: url("#{$img-root}/ui/gallery-arrow-neg-left.svg");
				
			}
			&--right {
				background-image: url("#{$img-root}/ui/gallery-arrow-neg-right.svg");
				
			}
			
			&--left {
				left: 0;

			}
			&--right {
				right: 0;

			}
			
		}
		
		.gallery__navigation__progress-bar-item {
			&:after {
				@apply bg-green;
				
			}
			
		}
		
	}
	
}

/*
	SliderProductGallerySection
*/

[data-class-name=SliderProductGallerySection] {
	max-width: 2640px;
	margin-left: auto;
	margin-right: auto;
	
	/*
	img {
		aspect-ratio: 1720/1208;
		object-fit: contain;
		
	}
	*/
	
	figcaption {
		display: none;
		
	}
	
	.gallery__slide--image {
		> *, picture {
			display: block;
			position: relative;
			
		}
		
	}
	
	.gallery__slide--text-overlay {
		position: absolute;
		z-index: 1;
		
		left: 20px;
		
		width: calc(100% - 40px);
		max-width: 570px;
		
		top: calc(100% - 20%);
		
		@apply bg-white;
		
		padding: 20px;
		
		@screen md {
			padding: 40px;
			width: calc(100% - 120px);
			
			top: calc(100% - 25.5%);
			
		}
		
		@screen xl {
			left: 30px;
			
		}
			
	}
	
	&.gallery--progress-dots-above {
		padding-top: 50px;
		
		@screen md {
			padding-top: 80px;
			
		}
		
		.gallery__navigation__progress-bar {
			margin-top: 0;
			margin-bottom: 0;
			
			position: absolute;
			top: 0;
			bottom: auto;
			
		}
		
	}
	
	&.gallery--no-scroll {
		padding-top: 0;
		
		.gallery__navigation,
		.gallery__navigation__progress-bar {
			display: none !important;
			
		}
		
	}
	
}
