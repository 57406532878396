/*
	Debug
*/

#debugOut {
	position: fixed;
	left: 0; top: 0;
	z-index: 819200;

	border-style: none;
	margin: 0;
	padding: 0;

	font-family: bold 11px/20px sans-serif;

	pre {
		margin: 2px 4px 0px 4px;

		background-color: rgba(255,255,255,.75);
		color: #303030;
		text-shadow: 0px 1px 2px #ffffff;

	}

	&.negative pre {
		background-color: rgba(0,0,0,.25);

		color: #e8e8e8;
		text-shadow: 0px 1px 2px #000;

	}

}

/*
	Display
*/

.sprite {
	position: absolute;
	left: 0;
	top: 0;

	margin: 0;
	padding: 0;

	-webkit-font-smoothing: antialiased;

	&--button {
		cursor: pointer;

	}

}

img.sprite, .fast-sprite {
	-webkit-perspective: 10px;
	-webkit-perspective-origin: 50% 50%

}

.shape {
	pointer-events: none;
	-webkit-touch-callout: none;

}

.shape, .unselectable {
	user-select: none;

}

object, embed {
	width: 100%;
	height: 100%;

	&:focus {
		outline: none;

	}

}

.horizontal-ruler,
.vertical-ruler,
.grid-ruler {
	@extend .shape;

	position: fixed;
	left: 0; top: 0;

}

.horizontal-ruler {
	width: 100%;
	height: 1px;

}

.vertical-ruler {
	width: 1px;
	height: 100%;

}

.grid-ruler {
	width: 100%;
	visibility: hidden;

}

/*
	UI
*/

body {
	&.point * {
		cursor: pointer;

	}

	&.grab * {
		cursor: grabbing !important;
		cursor: -moz-grabbing !important;
		cursor: -webkit-grabbing !important;

	}

	&.scroll-locked {
		// overflow: hidden;
		touch-action: none;

	}

}

/*
	Disable scroll bars
*/

%scroll-bar-disabled {
	& {
		-ms-overflow-style: none; // Internet Explorer 10+
		scrollbar-width: none;  // Firefox

	}

	&::-webkit-scrollbar {
		display: none; // Safari and Chrome

	}

}

html, body {
	// @extend %scroll-bar-disabled;
	// overflow-x: hidden;
	
}

html {
	background: #ffffff;
	
}

body {
	overflow-x: hidden;
	
}

/*
	Disable Cookie Backend
*/

.neos-backend #modal-cookie {
    display: none;
    
}

.animation-page-cover {
	@extend .shape;
	
	position: absolute;
	z-index: 256;
	
	height: 1200px * 12;
	
	left: -50vw;
	right: -50vw;
	
	background: linear-gradient(to bottom, rgba(#ffffff, 0) 0px, #ffffff 1200px, #ffffff 1200px * 11, rgba(#ffffff, 0) 1200px * 12) no-repeat;
	background-position: 50% -1200px;
	
}

[data-class-name=IntroAnimation].invisible,
[data-class-name=TypoAnimation]:not([data-processed=yes]) {
	visibility: visible !important;
	
	> * {
		visibility: hidden;
		
	}
	
	&:after {
		content: " ";
		
		@extend .animation-page-cover;
		
	}
	
}
