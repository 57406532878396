body {
	@apply min-h-screen;
	@apply flex;
	@apply flex-col;
	@apply items-stretch;

	position: relative;
	overflow-x: hidden;

}

main {
	flex: 1 0 auto;

	padding-top: 95px;

	@screen lg {
		padding-top: 100px;

	}
	
	body.transparent-header & {
		// padding-top: 0;
		
	}
	
}

.story main {
    padding-top: 0px;
}

%default-section-margin-top {
	/*
	margin-top: 20px;

	@screen md {
		margin-top: 40px;

	}

	@screen xl {
		margin-top: 60px;

	}
	*/

}

%default-section-margin-bottom {
	/*
	margin-bottom: 20px;

	@screen md {
		margin-bottom: 40px;

	}

	@screen xl {
		margin-bottom: 60px;

	}
	*/

}

.grid-margin-top {
	margin-top: 20px;

	@screen md {
		margin-top: 40px;

	}

	@screen xl {
		margin-top: 60px;

	}

}

%container-width {
	width: calc(100% - 40px);

	@screen sm {
		width: 520px;

	}

	@screen md {
		width: 680px;

	}

	@screen lg {
		width: 920px;

	}

	@screen xl {
		width: 1320px;

	}

	.container &:not(.nested) {
		width: 100%;

	}

}

.container {
	@apply mx-auto;
	@extend %default-section-margin-top;
	@extend %default-section-margin-bottom;

	@extend %container-width;

	&:before {
		content: " ";
		display: table;

	}

}

.row {
	@apply flex;
	@apply flex-wrap;

	margin: -10px;

	@screen md {
		margin: -20px;

	}

	@screen xl {
		margin: -30px;

	}

	+ .row {
		margin-top: 10px;

		@screen md {
			margin-top: 20px;

		}

		@screen xl {
			margin-top: 30px;

		}

	}

	&:last-child:not(li) {
		margin-bottom: -10px;

		@screen md {
			margin-bottom: -20px;

		}

		@screen xl {
			margin-bottom: -30px;

		}

	}

}

a + a > .row {
	margin-top: 10px;

	@screen md {
		margin-top: 20px;

	}

	@screen xl {
		margin-top: 30px;

	}

}

.column {
	@apply w-full;

	padding: 10px;

	@screen md {
		padding: 20px;

	}

	@screen xl {
		padding: 30px;

	}

	pointer-events: none;

	> * {
		pointer-events: auto;

	}

	&:empty {
		padding-top: 0;
		padding-bottom: 0;

	}

}

.box {
	@apply h-full;
	@apply bg-white;

	padding: 25px;

	@screen sm {
		@apply p-30;

	}

}

.section {
	@extend %default-section-margin-top;

	&:before,
	&:after {
		content: " ";
		display: table;

	}

	.container {

	}

}

.-left-15 {
	left: -15px;

}
