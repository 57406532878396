body.campaign-page {
	.campaign-background-shape {
		right: 0;
		
		width: 100%;
		max-width: 1800px;
		
		&:after {
			padding-bottom: 824.8%;
			background: url("#{$img-root-quick-mix}/campaign/background-375.svg") 100% 0%/contain no-repeat;
			
		}
		
		@screen sm {
			width: 140%;
			
			&:after {
				padding-bottom: 198.0446247464503%;
				background-image: url("#{$img-root-quick-mix}/campaign/background-1800.svg");
				
			}
			
		}
		
	}
	
}
