@tailwind base;
@tailwind components;

/**
 * Available path aliases: @sievert & @quickmix
 * for the asset directories in project
 */
@import "@sievert/Styles/_variables";
@import "@quickmix/Styles/_variables";
@import "@quickmix/Styles/_primary-color";
@import "@sievert/Styles/_base-fx";
/*! purgecss start ignore */
@import "@tubag/Styles/_typography";
@import "@sievert/Styles/_tables";
@import "@sievert/Styles/_images";
@import "@quickmix/Styles/_interaction";
@import "@sievert/Styles/_forms";
@import "@sievert/Styles/_grid";
@import "@sievert/Styles/_navigation";
@import "@sievert/Styles/_navigation-brands";
@import "@quickmix/Styles/_navigation";
@import "@quickmix/Styles/_background-shapes";
@import "@quickmix/Styles/_footer";
//@import "@sievert/Styles/_footer";
@import "@sievert/Styles/_accordion";
@import "@tubag/Styles/_accordion";
@import "@sievert/Styles/_ui";
@import "@sievert/Styles/_gallery";
@import "@sievert/Styles/_collage";
@import "@sievert/Styles/_video";
@import "@sievert/Styles/_sievert-logo-square";
@import "@sievert/Styles/_richtext";
@import "@sievert/Styles/_downloadList";
@import "@sievert/Styles/_product-detail-page";
@import "@quickmix/Styles/_product-detail-page-diy";
@import "@tubag/Styles/_product-overview-page";
@import "@quickmix/Styles/_typography";
@import "@sievert/Styles/_typography-campaign";
@import "@sievert/Styles/_campaign";
@import "@quickmix/Styles/_campaign";
@import "@sievert/Styles/_detail-drawings";
/*! purgecss end ignore */


// @import "@sievert/Styles/_grid-debug";

@tailwind utilities;
