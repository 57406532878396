body.campaign-page {
	position: relative;
	
	.campaign-background-shape {
		position: absolute;
		
		max-height: 100%;
		overflow: hidden;
		
		&:after {
			content: " ";
			display: block;
			
		}
		
	}
	
	main {
		position: relative;
		
		margin-top: 30px;
		
		@screen md {
			margin-top: 80px;
			
		}
		@screen lg {
			margin-top: 100px;
			
		}
		@screen xl {
			margin-top: 130px;
			
		}
		
		> :first-child > :first-child {
			h1.sievert-logo-square {
				margin-top: 0 !important;
				
			}
			
		}
		
	}
	
}
