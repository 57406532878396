/**
 * Sievert Logo Square
 * 1. in story teasers (image)
 * 2. in Headlines (text)
 */

/* 1. */

%sievert-logo-square-multiply {
	@apply bg-green;
	bottom: -20px;
	content: " ";
	height: 40px;
	right: 20px;
	mix-blend-mode: multiply;
	position: absolute;
	width: 40px;
	z-index: 1;

	@screen md {
		bottom: -40px;
		height: 80px;
		right: 40px;
		width: 80px;
	}

	@screen xl {
		bottom: -60px;
		height: 120px;
		right: 60px;
		width: 120px;
	}
}

%sievert-logo-square-multiply-reverse {
	@screen lg {
		left: 40px;
	}
	@screen xl {
		left: 60px;
	}
}

.sievert-logo-square-multiply {
    &::after {
        content: " ";

        @extend %sievert-logo-square-multiply;

    }

}

.sievert-logo-square-multiply-standalone {
	> div {
		@extend %sievert-logo-square-multiply;

	}

}

.flex-row-reverse {
    .sievert-logo-square-multiply {
        &::after {
			@extend %sievert-logo-square-multiply-reverse;

        }

    }

	.sievert-logo-square-multiply-standalone {
		> div {
			@extend %sievert-logo-square-multiply-reverse;

		}

	}

}

/* 2. */

/* add default indent 1/12 only for small devices */
.sm\:indent-1 {
    margin-left: 8.333333%;

    @screen md {
        margin-left: 0;
    }
}

.sievert-logo-square {
    padding-top: 20px;

    @screen md {
        padding-top: 30px;
    }

    @screen lg {
        padding-top: 40px;
    }

    @screen xl {
        padding-top: 60px;
    }

    &::before {
        @apply bg-green;
        content: " ";
        height: 20px;
        left: -20px;
        position: absolute;
        top: 0;
        width: 20px;

        @screen md {
            left: -30px;
            height: 30px;
            width: 30px;
        }

        @screen lg {
            left: -40px;
            height: 40px;
            width: 40px;
        }

        @screen xl {
            left: -60px;
            height: 60px;
            width: 60px;
        }
    }
}

.text-white .sievert-logo-square::before {
    @apply bg-white;
}
