form {
	label {
		@apply text-sm;

	}

	.invalid-feedback.d-block {
		display: block;

	}

	@screen xl {
		.xl\:space-x-30 > :not(template) ~ :not(template) {
			body.IE & {
				margin-left: 30px;

			}

		}

	}

	select {
		appearance: none;

		cursor: pointer;

		@extend %button-style;
		border-radius: 0;

		padding-left: 15px;
		padding-right: 35px;

		position: relative;

		background: white url("#{$img-root}/ui/drop-down-arrow.svg") calc(100% - 10px) calc(50% - 1px) no-repeat;

		overflow: hidden;
		text-overflow: ellipsis;

		&::-ms-expand {
			display: none;

		}

        &.select--minimal {
            border: 0;
            padding-top: 0;
            padding-left: 0;
            @apply .text-md;
		    background: white url("#{$img-root}/ui/drop-down-arrow.svg") calc(100% - 10px) calc(50% - 4px) no-repeat;
        }

		&:focus {
			outline: none;

		}

		> optgroup, option {
			font-family: "Roboto";

			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-smoothing: antialiased;

			@apply text-blue;
			@apply text-sm;

			font-weight: 400;

			background: white;

		}

	}

	input[type=text],
	input[type=file],
	input[type=email],
	input[type=date],
	input[type=tel],
    textarea {
		appearance: none;

		@extend %button-style;
		border-radius: 0;

		padding-left: 15px;
		padding-right: 15px;

		width: 100%;

		color: inherit;

		&:focus {
			outline: none;

			@apply border-green;

		}

	}

}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	&:not([aria-valuenow]) {
		color: green;

	}

}

.form-type-ahead {
	position: relative;
		
	input {
		border-radius: 0 !important;
		
	}
	
	input,
	.form-type-ahead-text {
		padding-left: 15px !important;
		padding-right: 15px !important;
		
	}
	
	.form-type-ahead-text {
		position: absolute;
		@apply inset-0;

		@extend %button-style;
		border-radius: 0;
		border-color: transparent !important;

		opacity: .2;

		padding-left: 15px;
		padding-right: 15px;

		pointer-events: none;
		-webkit-touch-callout: none;
		user-select: none;

	}

}
