.video-player {
	position: relative;
	
	cursor: pointer;
	
	&:after {
		content: " ";
		display: block;
		
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		
		// background: rgba(0, 255, 0, .4);
		
		background: url("#{$img-root}/ui/video-play-button.svg") calc(100% - 30px) calc(100% - 30px) no-repeat;
		
	}
	
	&:hover:after {
		background-image: url("#{$img-root}/ui/video-play-button-hover.svg");
		
	}
	
}

.overlay-video-player {
	position: fixed;
	z-index: 8194;
	left: 0;
	top: 0;
	
	width: 100%;
	
	&__background {
		overflow: hidden;
		
		background: #000000;
		// background: $dehn-deep-teal;
		
	}
	
	&__close-button {
		z-index: 1010;
		
		width: 40px;
		height: 40px;
		
		cursor: pointer;
		
		&:after {
			content: " ";
			display: block;
			
			position: absolute;
			z-index: 1010;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			
			width: 36px;
			height: 35px;
			
			background: url("#{$img-root}/ui/icon-close-negative.svg") 50% no-repeat;
			
		}
		
		left: auto !important;
		right: 15px;
		top: 15px !important;
		
		@media (max-height: 400px) {
			left: 15px !important;
			right: auto;
			
		}
		
	}
	
	.video-player-wrapper {
		transform-origin: 0 0;
		
		width: 100%;
		height: 100%;
		
		padding-top: 65px;
		
		@screen md {
			padding-bottom: 65px;
			
		}
		
		@media (max-height: 400px) {
			padding: 0;
			
		}
		
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		
		> div:not(.class) {
			position: relative;
			height: 100%;
			
		}
		
	}
	
}

iframe,
.vimeo-video-player {
	position: absolute;
	
	left: 0;
	top: 0;
	
	width: 100%;
	height: 100%
	
}
