a.link {
	cursor: pointer;

	&:hover {
		@apply text-quickmix-orange;

	}

}

%button-style {
	@apply text-sm;

	margin-left: -2px;

	border: 1.4px solid;
	border-radius: 24px;

	padding: 6px 25px;

	@apply border-grey;

}

%button-hover-effect {
	&:after {
		left: 0;
		width: calc(100% + 20px);

		height: 100%;
		top: auto;
		bottom: 0;

		opacity: 1;

	}

}

.button {
	display: inline-block;

	@extend %button-style;

	@apply bg-grey;
	@apply text-white;

	white-space: nowrap;

	&:hover, &.active {
		@apply bg-quickmix-orange;
		@apply border-quickmix-orange;

	}

	&.secondary {
		@apply bg-transparent;
		@apply text-grey;

		&:hover {
			@apply border-quickmix-orange;
			@apply text-quickmix-orange;

		}

	}

	&.primary {
		position: relative;
		z-index: 1;
		overflow: hidden;

		border: none;
		padding: 7px 26px;

		&:after {
			content: " ";
			position: absolute;

			right: -20px;
			width: 0;

			top: 0;
			height: 0;

			z-index: -1;

			transform-origin: 0 0;
			transform: skew(-23deg);

			@apply bg-quickmix-orange;
			opacity: .5;

			transition: all .33s;

		}

		&:hover {
			background: #000000;

			@extend %button-hover-effect;

		}

	}

    .bg-green &,
    .bg-blue & {
        @apply bg-white;
        @apply border-white;
        @apply text-black;
    }

    .bg-green & {
        &:hover {
            @apply bg-grey;
            @apply border-grey;
            @apply text-white;
        }
    }

    .bg-blue & {

        &:hover {
            @apply bg-quickmix-orange;
            @apply border-quickmix-orange;
            @apply text-white;
        }
    }

    &.secondary {

        .bg-green &,
        .bg-blue & {
            @apply bg-transparent;
            @apply border-white;
            @apply text-white;
        }

        .bg-green & {

            &:hover {
                @apply border-grey;
                @apply text-grey;
            }
        }

        .bg-blue & {

            &:hover {
                @apply border-quickmix-orange;
                @apply text-quickmix-orange;
            }
        }
    }
}

a:hover {
	.button.primary {
		@extend %button-hover-effect;

	}

	.button.secondary {
		@apply border-quickmix-orange;
		@apply text-quickmix-orange;

	}

}

.text-link, .text-link-highlight {
	display: inline-block;
	padding-right: 1em;

	&:after {
		content: " ";
		display: inline-block;

		width: 1em;
		margin-right: -1em;
		height: .55em;

		background: url("#{$img-root}/ui/text-link-arrow-black.svg") 100% 50%/contain no-repeat;

		position: relative;
		top: .025em;

		body.IE & {
			background-position: 85% 50%;

		}

	}

	&:hover {
		@apply text-quickmix-orange;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-orange.svg");

		}

	}

	.bg-quickmix-orange &,
    .bg-green &,
    .bg-blue & {
		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-white.svg");

		}

	}

}

.text-link {
    @apply text-sm;

    @screen md {
        @apply text-md;
    }

	@apply leading-relaxed;

}

.text-link-highlight {
	@apply text-xl;
	@apply leading-snug;

}

.text-link,
.text-link-highlight {

    .bg-quickmix-orange &:hover,
    .bg-green &:hover {
        @apply text-grey;

        &:after {
            background-image: url("#{$img-root}/ui/text-link-arrow-grey.svg");
        }
    }

    .bg-blue &:hover {

        &:after {
            background-image: url("#{$img-root}/ui/text-link-arrow-orange.svg");
        }
    }
}

.teaser .bg-green {
    @apply text-white
}

a:hover {
	.text-link, .text-link-highlight {
		@apply text-quickmix-orange;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-orange.svg");

		}

	}

	.bg-quickmix-orange,
    .bg-green {
		.text-link, .text-link-highlight {
			@apply text-grey;

			&:after {
				background-image: url("#{$img-root}/ui/text-link-arrow-grey.svg");

			}

		}

	}

}

.rich-text a:hover {
	@apply text-quickmix-orange;
	text-decoration-color: inherit !important;
	padding-bottom: 1px;

}

.rich-text,
[id^=press] {

    a {

        &:hover {

            .bg-green.text-white & {
                color: var(--secondary-color);
                text-decoration-color: var(--secondary-color);
            }
        }
    }
}
