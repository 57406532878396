.accordion {
	.accordion-item-head {
		@apply font-medium;
		
	}
	
	.rich-text,
	.product-download-table {
		max-width: 960px;
		
	}
	
	table:not(.generic-table) {
		width: 100%;
		
		&, tbody, tr, td {
			display: block;
			
		}
		
		tr {
			margin-bottom: 0;
			
			+ tr {
				margin-top: 7px;
				
			}
			
		}
		
		td {
			&:first-child {
				margin-top: 1em;
				
			}
			&:last-child {
				margin-bottom: 1em;
				padding-bottom: 0;
				
			}
			
			&:not(:first-child) {
				padding-top: 0;
				
			}
			
			vertical-align: top;
			
		}
		
		@screen sm {
			& {
				display: table;
				
			}
			tbody {
				display: table-row-group;
				
			}
			tr {
				display: table-row;
				
			}
			td {
				display: table-cell;
				
				&:not(:first-child) {
					padding-top: 20px;
					padding-left: 20px;
					
				}
				
			}
			
		}
		
	}
	
	.technical-data-table {
		tr > td:first-child {
			@apply font-medium;
			
		}
		
	}
	
	.product-download-table {
		tr {
			td:last-child {
				padding-right: 0;
				a {
					margin-left: auto;
					
				}
				
			}
			
		}
		
	}
	
	.technical-data-table,
	.product-download-table {
		tr {
			border-top: 1px solid currentColor;
			
			@screen sm {
				td {
					padding-top: 1em;
					padding-bottom: 1em;
					
				}
				
			}
			
		}
		border-bottom: 1px solid currentColor;
		
	}
	
}

