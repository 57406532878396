.accordion-item {
	.accordion-item-head {
		position: relative;
		padding-right: 2em;
		margin-bottom: -1px;

		&:after {
			content: " ";
			display: block;

			width: 1.2em;
			height: 1.2em;

			position: absolute;
			right: 0;
			@apply top-30;
			margin-top: 2px;

			background: url("#{$img-root}/ui/accordion-arrow-down.svg") 50%/contain no-repeat;
			transform: rotate(-90deg);

			transition: transform .45s;

		}

	}

	.accordion-item-body {
		display: none;
		overflow: hidden;

		&:before, &:after {
			content: " ";
			display: block;

			width: 100%;
			height: 1px;
			margin-bottom: -1px;

		}

		.row {
			&.my-30 {
				@apply my-30;

			}

			.column {
				@apply py-0;

			}

		}

	}

	&.open {
		.accordion-item-head {
			&:after {
				transform: rotate(0);

			}

		}

		.accordion-item-body {
			display: block;

		}

	}

    .text-white & {

        .accordion-item-head {

            &:after {
                background-image: url("#{$img-root}/ui/accordion-arrow-down-white.svg");
            }
        }
    }

}
