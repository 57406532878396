[data-class-name=ProductOverviewSlider] {
	&.scrollable {
		margin-top: 15px;
		
		flex-wrap: initial;
		@extend .unselectable;
		
	}
	
}

ul.filter-values {
	li.button {
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
		overflow: hidden;
		
		&:hover {
			@apply bg-black;
			@apply border-black;
			opacity: .667;
			
		}
		
		&.active, &.bg-tubag-red, &.bg-primary {
			&, &:hover {
				@apply bg-primary;
				@apply border-primary;
				opacity: 1;
				
			}
			
		}
		
	}
	
}
