/*
	Navigation
*/

.page-header {
	font-size: 1rem;
	
	position: fixed;
	z-index: 1024;

	width: 100%;

	&, .container:not(.locale-layer-container) {
		height: 96px;

		@screen lg {
			height: 99px;

		}

	}

	.container:not(.locale-layer-container) {
		margin-top: 0;
		max-width: 1320px;

		width: calc(100% - 40px);

		@screen md {
			width: calc(100% - 80px);

		}

		@screen lg {
			min-width: 1040px - 120px;
			width: calc(100% - 120px);
			max-width: 1320px;

		}

	}

	.column {
		display: flex;

		margin-top: 0;
		margin-bottom: 0;

	}

	&--shift-container {
		> .container {
			position: relative;
			z-index: 1;

			> .row > .col {
				display: flex;
				justify-content: space-between;

				width: 100%;

			}

		}

	}

	.page-header-cover {
		height: 100%;

		body.transparent-header & {
			overflow: hidden;
			will-change: transform, clip-path;

			background: rgba(#ffffff, .925);
			backdrop-filter: brightness(120%) contrast(110%);

		}

	}

	&.reduced .page-header-cover {
		height: 100%;

	}
	
	&--shift-container:before {
		content: " ";
		display: block;

		position: absolute;
		left: 0;
		top: 0;
		width: 100%;

		background: transparent;

		transform: translate3D(0, -200%, 0);
		transition: transform .175s, background-color .15s;
		
		/*
		body.transparent-header & {
			content: none;
			
		}
		*/
		
	}

	&--shift-container:before {
		height: 100%;

	}
	
	&.covering {
		.page-header--shift-container:before {
			transform: translate3D(0, 0, 0);
			background: #ffffff;

		}

		.page-header-cover {
			transition: none;

		}

	}
	
	.page-header-cover {
		position: absolute;
		z-index: 1;

		left: 0;
		top: 0;
		width: 100%;

	}

	.col > *,
	.page-navigation-overlay {
		pointer-events: auto;

	}

}

.header-logo {
	display: block;
	transform: translateZ(0);

	background: url("#{$img-root}/header/sievert-logo.svg") 0 50%/contain no-repeat;

	margin-top: 35px - 2px;

	width: 116px;
	height: 29px;

	@screen lg {
		margin-top: 35px - 1px;

		width: 116px;
		height: 29px;

	}

}

@mixin underlined {
	@media (hover: hover) {
		content: " ";
		display: block;

		position: absolute;

		left: 0;

		width: 100%;
		height: 1px;

		background: currentColor;

		bottom: -2px;

		@screen md {
			bottom: -5px;

		}

	}

}

%underlined {
	@include underlined;

}

.page-tools {
	white-space: nowrap;

	margin-top: 29px;

	@screen lg {
		margin-top: 31px;

	}

	.page-header.reduced & {
		display: none;

	}

	> div {
		display: inline-block;

	}
	
	display: flex;
	
	.page-tool-language {
		.page-header.reduced & {
			display: none;

		}

		margin-top: 5px;

		display: none;
		@screen lg {
			display: inline-block;

		}

		margin-left: 35px;
		margin-right: 0;

		> a {
			color: inherit;
			padding-bottom: 3px;
			
			@media (hover: hover) {
				&:hover {
					// @apply text-green;
					// border-bottom: 1px solid;

				}

			}
			
		}

	}

	.page-tool-menu {
		@screen lg {
			display: none;

		}

		position: relative;

		margin-left: 15px;
		margin-right: -1px;

		background: url("#{$img-root}/header/icon-menu.svg") 50%/contain no-repeat;

		cursor: pointer;

		header.page-header.expanded & {
			background-size: 20px;
			background-repeat: no-repeat;

			background-image: url("#{$img-root}/header/icon-close.svg");

		}

	}

}

header.page-header.expanded {
	.page-tool-search,
	.page-tool-locale {
		display: none;
		
		@screen lg {
			display: block;
			
		}
		
	}
	
}

%text-page-tool {
	height: 21px;
	width: auto;

}

.page-tool-icon {
	position: relative;

	width: 23px;
	height: 23px;

	top: 7px;

	background: 50% no-repeat;

	cursor: pointer;

	+ .page-tool-icon {
		margin-left: 30px;

	}

	&.page-tool-search {
		margin-right: -4px;

		background-image: url("#{$img-root}/header/icon-search.svg");

	}

	&.page-tool-locale {
		margin-right: -4px;
		
		@screen lg {
			margin-right: 2px;

		}

		background-image: url("#{$img-root}/header/icon-locale-black.svg");
		
		&:after {
			content: " ";
			position: absolute;
			
			width: 100%;
			top: -1px;
			bottom: 0;
			
			background: #ffffff url("#{$img-root}/header/icon-close.svg") 50%/20px no-repeat;
			
			opacity: 0;
			
			transition: opacity .2s;
			
		}
		
		&.open {
			display: none;
			
			@screen lg {
				display: block;
				&:after {
					opacity: 1;
					
				}
				
			}
			
		}
		
	}

	&.page-tool-menu {
		height: 24px;
		margin-bottom: -1px;

		background-image: url("#{$img-root}/header/icon-menu.svg");

	}

	&.page-tool-close {
		position: relative;
		left: 1px;

		height: 21px;

		top: -22px;
		width: 21px;

		font-size: 0;

		background-size: 20px;

		background-image: url("#{$img-root}/header/icon-close.svg");

		@extend %text-page-tool;

		@screen md {
			top: -1px;

			@apply text-md;
			background: none;

			&:hover:after {
				@include underlined;

			}

		}

	}

}

.page-tools-reduced {
	display: none;

	white-space: nowrap;

	.page-header.reduced.modal-story &.page-tools-story {
		display: block;

	}

	.page-header.reduced.modal-lightbox &.page-tools-lightbox,
	.page-header.reduced.modal-video &.page-tools-lightbox {
		display: block;

	}

	> div {
		display: inline-block;

		cursor: pointer;

	}

}

.navigation-tools-mobile {
	.col > & {
		display: none;
		
	}
	
	transition: opacity .25s;
	
	&.hidden {
		display: block !important;
		opacity: 0;
		pointer-events: none;
		
		transition: none;
		
	}

	@screen lg {
		display: none;

	}

	a {
		color: inherit;

	}

	> * {
		cursor: pointer;

	}

	> * + * {
		margin-top: 12px;

	}
	
	padding-bottom: 50px;
	
	.page-tools + & {
		display: none;
		
	}

}

.page-navigation {
	margin-left: auto;
	margin-right: auto;

	.page-header.reduced & {
		visibility: hidden;

	}

	ul {
		list-style: none;

		padding: 0;

		li {
			> a {
				color: inherit;

			}

		}

	}

	> ul {
		display: none;

		@screen lg {
			display: block;

		}

		margin-top: 36px;

		> li {
			display: inline-block;
			margin-left: 11px;
			margin-right: 11px;

			@screen lg {
				margin-left: 20px;
				margin-right: 20px;

			}

			@screen xl {
				margin-left: 25px;
				margin-right: 25px;

			}

			> a {
				padding-bottom: 5px;
				font-weight: 500;

				&:hover {
					text-decoration: none;

					@media (hover: hover) {
						border-bottom: 2px solid currentColor;

					}

				}

			}

			&.current {
				> a {
					border-bottom: 1px solid currentColor;

					@screen lg {
						border-bottom: 2px solid;

					}

				}

			}

			&.active,
			&.current {
				> a {
					border-bottom: 2px solid currentColor;

					@screen lg {
						border-bottom: 2px solid;

					}

				}

			}

			> div {
				display: none;

			}

		}

	}

	&.opened {
		> ul {
			> li {
				&.active:not(.current) {
					> a {
						border-bottom: none;

					}

				}

			}

		}

	}

}

.page-navigation-overlay {
	background: #ffffff;
	border-bottom: 1px solid rgba(20, 38, 77);
	// @apply border-blue;

	box-sizing: content-box;

	position: absolute;
	top: 0;

	&.transition-layer {
		@extend %header-offset-top;

	}

	width: 100%;

	overflow: hidden;

	> .container > .row > .column > div {
		position: relative;
		width: 100%;

	}

	.page-navigation-overlay-close-button {
		position: absolute;
		z-index: 1024;

		right: 0;
		display: none;

		top: 100px;

		@screen lg {
			top: 104px;
			display: block;

		}

	}

	.page-tool-language,
	.page-tool-language-mobile {
		position: absolute;
		z-index: 1024;

		left: 0;
		top: 25px;

		a {
			color: inherit;
			border-bottom: 1px solid;
			text-transform: lowercase;

			display: none;
			margin: 0;

			&:hover {
				text-decoration: none;

			}

			&:not(.active) {
				display: block;

			}

		}

	}

}

%header-offset-top {
	top: 96px !important;

	@screen sm {
		top: 99px !important;

	}

	.transition-layer & {
		top: 0 !important;

	}

}

.page-navigation-level-container-wrapper {
	@extend %header-offset-top;

	background: #ffffff;

	&:before {
		content: " ";
		display: block;

		position: absolute;
		z-index: 1;

        left: -30px;
        width: 30px;
        height: 100%;

		background: #ffffff;

	}

	& + &:before {
		left: -30px;
		width: 30px;

	}

}

.page-navigation-level-container {
	position: absolute;
	width: 100%;

	overflow-y: auto;
	@extend %scroll-bar-disabled;

	.page-navigation-back-link {
		position: absolute;
		z-index: 1;

		padding-top: 2px;
		top: 5px;

		cursor: pointer;

		font-weight: 500;

		display: table;

		@media (hover: hover) {
			span:hover {
				padding-bottom: 5px;
				border-bottom: 2px solid;
				margin-bottom: -1px;

			}

		}

		&:before {
			content: " ";
			display: inline-block;
			
			background: url("#{$img-root}/header/navigation-arrow-back.svg") 50% no-repeat;
			
			width: 16px;
			height: 16px;
			
			margin-right: 8px;
			
			position: relative;
			top: 3px;

		}

		&.hidden {
			visibility: hidden;

		}

	}

}

ul.page-navigation-level {
	list-style: none;

	margin: 0;
	padding: 65px 0;

	li {
		display: block;

		& + li {
			margin-top: 12px + 4px;

		}

		> a {
			color: inherit;

			padding-bottom: 2px;
			
			@screen md {
				padding-bottom: 5px;
				
			}
			
			border-bottom: 2px solid transparent;

			line-height: 26px;

			@screen md {
				line-height: 24px;

			}

			&:hover {
				text-decoration: none;
				@media (hover: hover) {
					border-bottom-color: currentColor;

				}

			}

		}

		&.page-navigation-item--with-sub-items > a {
			&:after {
				content: " ";
				display: inline-block;

				background: url("#{$img-root}/header/navigation-arrow-right.svg") 50% no-repeat;

				width: 16px;
				height: 16px;

				margin-left: 4px;
				margin-right: -18px;

				position: relative;
				top: 3px;

				@screen lg {
					top: 3px;
					margin-left: 3px;
					height: 18px;

				}

			}

		}

		&.current > a {
			&:after {
				background-image: url("#{$img-root}/header/navigation-arrow-right-active.svg")

			}

		}

		&.active,
		&.current {
			> a {
				border-bottom-color: currentColor;

			}

		}

	}

	&.opened {
		li {
			&.active:not(.current) {
				> a {
					border-bottom-color: transparent;

				}

			}

		}

	}

	> li {
		> div {
			display: none;

		}

	}

	&.page-navigation-level-one {
		> li {
			> a {
				font-weight: 500;
				
			}
			
			&.current > a {
				&:after {
					background-image: url("#{$img-root}/header/navigation-arrow-right-active.svg")
					
				}
				
			}
			
			+ li {
				margin-top: 14px;
				
			}
			
		}
		
	}
	
	&.page-navigation-level-two {
		li.current > a {

		}

		> li {
			padding: 0;

			+ li {
				margin-top: 36px;

				@screen md {
					margin-top: 58px;

				}

				@screen xl {
					margin-top: 84px;

				}

			}

			> a {
				display: table;

				padding-bottom: 0;

				&:not(:hover) {
					border-bottom-color: transparent;

				}

				margin-bottom: 18px;

				@screen md {
					margin-bottom: 23px;

				}

				font-weight: 600;

			}

			&.active.has-no-active-child {
				> a {
					border-bottom-color: currentColor;

				}

			}

			&.page-navigation-item--with-sub-items > a {
				&:after {
					content: none;

				}

			}

			> div {
				display: block;

				> ul {
					padding: 0;

					> li > div {
						display: none;

					}

				}

			}

		}

	}

}

.page-navigation-teaser {
	padding-top: 76px;
	padding-bottom: 70px;

	@screen lg {
		padding-top: 72px;
		padding-bottom: 72px;

	}

	padding-right: 10px;
	max-width: 300px;

	@extend %header-offset-top;

    h4 {
        max-width: 280px;
        font-size: 32px;
        line-height: 1.2em;
        margin-top: -4px;

        margin-bottom: .6em;

	}

	p {
		a, a.text-link {
			font-size: 16px;
			line-height: 24px;

			body.IE & {
				&:after {
					width: 1.3em;
					height: 12px;

				}

			}

		}

	}

}

.navigation-text {
	font-weight: 500;
	
	strong {
		font-weight: 600;
		
	}
	
	/*
	margin-top: -46px;
	
	@screen lg {
		margin-top: 0;
		
	}
	*/
	
}

/*
	Search field
*/

.search-field {
	position: absolute;

	overflow: hidden;

	height: 59px;
	padding-top: 16px;

	height: 96px;

	padding-left: 2px;
	padding-top: 33px;

	@screen lg {
		height: 99px;

		padding-left: 2px;
		padding-top: 35px;

	}

	form {
		position: relative;
		width: calc(100% - 70px);

		input {
			font-size: 1rem;
			display: block;
			appearance: none;

			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
			  display: none;

			}

			&:focus {
				outline: none;
				border: none;

			}

			color: currentColor;

			&::placeholder {
				color: currentColor;
				opacity: .5;
				
			}
			
		}
		
	}
	
	.page-tool-close {
		position: absolute;
		left: auto;

		width: 23px;
		height: 23px;

		background: url("#{$img-root}/header/icon-close.svg") 50%/18px no-repeat;

		top: 37px;
		right: 20px;

		@screen lg {
			top: 37px;
			right: 6px;

		}

		&:hover:after {
			content: none;

		}

	}

}

/*
	Locale Layer
*/

.locale-layer {
	position: absolute;
	width: 100%;
	
    background: #ffffff;
    border-bottom: 1px solid rgba(#000000, .2);
    box-sizing: content-box;
	
	overflow: hidden;
	user-select: text;
	pointer-events: auto;
	
	> div {
		@extend %scroll-bar-disabled;
		
		margin-top: 60px + 78px;
		padding-bottom: 50px;
		
		@screen sm {
			.column {
				margin-left: 16.666667%;
				
			}
			
		}
		
		@screen md {
			// margin-top: 78px + 78px;
			padding-bottom: 75px;
			
		}
		
		@screen lg {
			margin-top: 178px;
			padding-bottom: 85px;
			
			.column {
				margin-left: 0;
				
			}
			
		}
		
	}
	
	.locale-layer-container {
		.column {
			flex-direction: column;
			
			&.sm\:w-2\/12.lg\:w-0.xl\:w-2\/12 {
				display: none;
				
				@screen xl {
					display: flex;
					
				}
				
			}
			
		}
		
		.locale-item {
			font-weight: 500;
			margin-bottom: .75rem;
			
			&:before {
				content: " ";
				
				display: inline-block;
				width: 14px;
				height: 11px;
				
				margin-right: .5em;
				
				background: 50%/cover no-repeat;
				
			}
			
			&-DE:before {
				background-image: url("#{$img-root}/header/locale-flag-DE.png");
				
			}
			
			&-BE:before {
				background-image: url("#{$img-root}/header/locale-flag-BE.png");
				
			}
			
			&-FR:before {
				background-image: url("#{$img-root}/header/locale-flag-FR.png");
				
			}
			
			&-LU:before {
				background-image: url("#{$img-root}/header/locale-flag-LU.png");
				
			}
			
			&-NL:before {
				background-image: url("#{$img-root}/header/locale-flag-NL.png");
				
			}
			
			&-INT:before {
				background-image: url("#{$img-root}/header/locale-flag-INT.png");
				
			}
			
			&-PL:before {
				background-image: url("#{$img-root}/header/locale-flag-PL.png");
				
			}
			
			&-CZ:before {
				background-image: url("#{$img-root}/header/locale-flag-CZ.png");
				
			}
			
			&-RU:before {
				background-image: url("#{$img-root}/header/locale-flag-RU.png");
				
			}
			
			&-CN:before {
				background-image: url("#{$img-root}/header/locale-flag-CN.png");
				
			}
			
			&-DK:before {
				background-image: url("#{$img-root}/header/locale-flag-DK.png");
				
			}
			
			a {
				font-weight: 400;
				text-decoration: underline;
				text-underline-offset: 3px;
				
			}
			
			.locale-item-language {
				margin-left: 1.25em;
				
			}
		}
		
	}
	
}
