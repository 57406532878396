body.campaign-page {
    font-family: "Helvetica Now Text W01", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

    color: #000000;

    @extend %copy-text;

    h1, .h1 {
        @apply font-bold;
        @apply leading-tight;

        @apply text-3xl;

        @screen md {
            @apply text-5xl;

        }

        @screen lg {
            @apply text-6xl;

        }

    }

    /* headline below convex or concave header image */
    .transparent-header h1 {
        margin-top: 0;
    }

    h2, .h2 {
        @apply font-bold;
        @apply leading-snug;

        @apply text-xl;

        @screen md {
            @apply text-2xl;

        }

        @screen lg {
            @apply text-4xl;

        }

    }

    .story-teaser-headline {
        padding-top: 0.5em;

        @apply w-10/12;

        @screen md {
            @apply w-9/12;

        }

        @screen lg {
            @apply w-full;

        }

    }

    h3, .h3 {
        @apply font-bold;
        @apply leading-normal;

        @apply text-lg;

        @screen md {
            @apply text-xl;

        }

    }

    h4, .h4 {
        @apply font-medium;
        @apply leading-normal;

        @apply text-sm;

        @screen md {
            @apply text-md;

        }

    }

    .neos-backend .whitespace-no-wrap {
        position: relative;
        padding: 0 .25em;

        &:after {
            content: " ";
            position: absolute;
            @apply inset-0;

            border-left: 2px dotted;
            border-right: 2px dotted;
            border-bottom: 2px dotted;
            transform: translateY(.15em);

            opacity: .425;

            pointer-events: none;

        }
    }

    p.leading, .leading {
        @apply text-lg;
        @apply leading-normal;

        @screen md {
            @apply text-xl;

        }
        /*
        br + br {
            display: none;

        }
        */
    }

    p, .p, %copy-text {
        @apply font-normal;
        @apply leading-relaxed;

        @apply text-sm;

        @screen md {
            @apply text-md;

        }

    }

    h1 + .leading {
        margin-top: 1em;

        @apply leading-normal;

        @apply text-xl;

        @screen md {
            @apply text-3xl;

        }

        @screen lg {
            @apply text-4xl;

        }

    }

    h1.sievert-logo-square {
        @screen md {
            width: 80%;

        }

    }

    %link-style {
        @apply text-xs;
        @apply leading-loose;

        @screen md {
            @apply text-sm;

        }

    }

    .link {
        display: table;

        @extend %link-style;

        + .link {
            @apply mt-15;

        }

    }

    p.info-text, .info-text {
        @apply font-medium;
        @apply text-2xs;
    }

    .whitespace-no-wrap {
        white-space: nowrap;
    }

    .whitespace-pre-line {
        white-space: nowrap;

        .neos-backend & {
            position: relative;
            padding: 0 .25em;

            &:after {
                content: " ";
                position: absolute;
                @apply inset-0;

                border-left: 2px dotted;
                border-right: 2px dotted;
                border-bottom: 2px dotted;
                transform: translateY(.15em);

                opacity: .425;

                pointer-events: none;

            }

        }

    }


}

