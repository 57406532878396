body {
    color: #3d3d3b;
}

p.leading, .leading, .rich-text {
	a {
		text-decoration: underline;
		&:hover {
			@apply text-quickmix-orange;
		}
	}
}

.rich-text ul > li:before {
	top: .1em;

}

/* DIY */

.diy,
.headline-with-background {

    h1.sievert-logo-square,
    .rich-text h2,
    .rich-text h3  {
        display: inline;
        color: white;
        box-shadow: 10px 0 0 0 var(--primary-color), -10px 0 0 0 var(--primary-color);
        background-color: var(--primary-color);
        box-decoration-break: clone;
        line-height: 1.6;
        padding: 0;
    }

    h1.sievert-logo-square::before {
        content: "";
        display: none;
        height: 0;
        width: 0;
    }

    .sm\:indent-1 {
        margin-left: 0;
    }

    &.bg-green {

        h1.sievert-logo-square,
        .rich-text h2,
        .rich-text h3  {
            box-shadow: 10px 0 0 0 white, -10px 0 0 0 white;
            background-color: white;
            color: var(--primary-color);
        }
    }
}
