.rich-text {
	h3 {
		margin-bottom: 1rem;

	}
	* + h3 {
		margin-top: 2.2rem;

	}

	h4, h5 {
		margin-bottom: .6rem;

	}

	h5 {
		@apply font-medium;

	}

	* + h4,
	* + h5 {
		margin-top: 1.8rem;

	}

	li {
		list-style: none;

		position: relative;
		padding-left: 1.35em;

		&:before {
			position: absolute;
			left: 0;

		}

		+ li {
			margin-top: .6em;

		}

	}

    ul > li {
        list-style: none;

        position: relative;
        padding-left: 1.2em;

        &:before {
            content: "▪";
            top: -0.1em;

        }

    }

    ol {
		counter-reset: list-counter;

		> li {
			counter-increment: list-counter;

			&:before {
				content: counter(list-counter) ".";

			}

		}

    }

    p, ul, ol {
		+ p, + ul, + ol {
			margin-top: .6em;

		}

    }

}

.rich-text,
[id^=press] {
    a {
		text-underline-offset: .3em;
		text-decoration-thickness: 2px;

		overflow-wrap: break-word;
		word-wrap: break-word;

        &:hover {
            text-decoration-color: #85bc22;

			.bg-green & {
                color: #14264D;
				text-decoration-color: #14264D;

			}

        }

    }

}
