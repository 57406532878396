/*
	Collage
*/

.column .collage-inner-4-col-image {
	width: calc(58.333333% - 8px);
	
	@screen md {
		width: 63%;
		
	}
	
	@screen lg {
		width: 64%;
		
	}
	
	@screen xl {
		width: 63.5%;
		
	}
	
}

.ml-col-1 {
	margin-left: calc(8.3333%);
	
}

.mr-col-1 {
	margin-right: calc(8.3333%);
	
}

.column > .w-10\/12 {
	width: calc(83.333333% - 3px);

	@screen md {
		&.md\:w-full {
			width: 100%;
			
		}
		
	}
	
}
