[data-class-name=DetailDrawings] {
	.download-filter {
		&.hidden {
			display: none;
			
		}
		
		h4 {
			margin-top: 24px;
			
			@screen md {
				margin-top: 27px;
				
			}
			
		}
		
		.button.active {
			background-color: var(--primary-color);
			border-color: var(--primary-color);
			
		}
		
	}
	
	.py-3 {
		padding-top: .8rem;
		padding-bottom: .725rem;
		
	}
	
	.downloads-column {
		width: 200px;
		
	}
	
	$lightbox-background: #332e28;
	
	.datail-drawing-overlay {
		position: fixed;
		z-index: 8193;
		
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		height: 100dvh;
		
		color: #ffffff;
		
		> * {
			position: relative;
			
		}
		
		&-background {
			position: absolute;
			
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			
			background: $lightbox-background;
			
		}
		
		&-controls {
			z-index: 1;
			pointer-events: none;
			
			font-size: 15px;
			
			background: rgba($lightbox-background, .9);
			
			.column {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: flex-end;
				
				> * {
					pointer-events: auto;
					
				}
				
				.header-logo {
					filter: brightness(0) invert(1);
					
				}
				
				.close-button {
					&:after {
						content: "";
						display: inline-block;
						
						width: 20px;
						height: 20px;
						margin-bottom: -5px;
						margin-left: 10px;
						
						background: url("#{$img-root}/ui/icon-close-negative.svg") 50%/contain no-repeat;
						
					}
					
				}
				
			}
			
		}
		
		&-content {
			position: absolute;
			
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			height: 100dvh;
			
			overflow: auto;
			
			.container {
				@media (min-width: 768px) and (max-width: 1023px) {
					width: calc(100% - 80px);
					max-width: none;
					
				}
				@media (min-width: 1440px) {
					max-width: 1080px + 10;
					
				}
				
			}
			
			.row {
				min-height: 100vh;
				min-height: 100dvh;
				
				margin-top: 0;
				margin-bottom: 0;
				
				padding-top: 80px;
				padding-bottom: 30px;
				
				@media (max-width: 767px) {
					flex-direction: column;
					
				}
				
				@screen md {
					padding-top: 80px;
					padding-bottom: 80px;
					
				}
				
				> .column {
					display: flex;
					flex-direction: column;
					
					justify-content: center;
					
					&:first-child {
						overflow: hidden;
						
						height: calc(100vh - 380px - 60px);
						min-height: 270px;
						
						margin-bottom: 10px;
						
						@screen md {
							height: auto;
							min-height: 610px;
							
							margin-bottom: 0;
							
						}
						
						> div {
							position: relative;
							
							flex: 1 0 auto;
							
						}
						
						img, object, embed {
							position: absolute;
							
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							
							object-position: 50% 50%;
							object-fit: contain;
							
						}
						
					}
					
					&:last-child {
						position: relative;
						
						justify-content: flex-start;
						
						> div {
							flex: 0 0 50%;
							
							display: flex;
							flex-direction: column;
							
							justify-content: flex-end;
							
						}
						
						@media (max-width: 767px) {
							&, > div {
								flex: 1 0 auto;
								
							}
							
						}
						
						max-width: 360px;
						margin-left: auto;
						margin-right: auto;
						
						@screen md {
							max-width: none;
							
						}
						
					}
					
				}
				
			}
			
			.datail-drawing-downloads {
				display: flex;
				
			}
			
			.datail-drawing-navigation {
				@screen md {
					position: absolute;
					top: 50%;
					width: calc(100% - 40px);
					
					@screen xl {
						width: calc(100% - 60px);
						
					}
					
				}
				
				@media (max-width: 767px) {
					flex: 1 0 auto;
					align-items: flex-end;
					
					// margin-bottom: 50px;
					
				}
				
				display: flex;
				justify-content: space-between;
				
				&-button {
					cursor: pointer;
					
					&:first-child:before,
					&:last-child:after {
						content: "";
						display: inline-block;
						
						width: 12px;
						height: 20px;
						margin-bottom: -5px;

						background: url("#{$img-root}/ui/overlay-arrow-left.svg") 50%/contain no-repeat;
						
						opacity: .8;
						
					}
					
					&:first-child:before {
						margin-right: 4px;
						
					}
					
					&:last-child:after {
						margin-left: 4px;
						
						transform: scaleX(-1);
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
