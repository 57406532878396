picture {
	display: block;
}

img {
	display: block;
	width: 100%;
	height: auto;
}

.lazyload {
	img {
		opacity: 0;
		transition: opacity .125s ease-out;
	}

	&--complete {
		img {
			opacity: 1;
		}
	}
}

.lazyload--no-anim {
	img {
		opacity: 1;
		transition: none;
	}
}

.imageTeaserLinkGroup a,
.imageTeaserLinkGroup span{
    width: fit-content;
}
