/**
 * Product Detail Page
 *
 */

.product-detail-page {
    /* Text */
    .product-breadcrumb  {
        font-weight: 400;

        a {
            &:not(:first-of-type) {
                margin-left: 30px;
                position: relative;
            }

            &:before {
                content: "";
                display: inline-block;

                width: .5em;
                height: .5em;

                border-top: 1px solid currentColor;
                border-right: 1px solid currentColor;

                transform: rotate(45deg);

                left: -20px;
                position: absolute;
                top: .5em;
            }
        }
    }

    /* main headline */

	h1 {
		@extend .h2;

	}

    @screen xl {
        .sievert-logo-square {
            padding-top: 40px;

            &::before {
                height: 40px;
                left: -40px;
                width: 40px;
            }
        }
    }

    /*
		accordion
    */

	.accordion.border-solid.border-b-2,
	.accordion-item.border-solid.border-t-2 {
		&.border-warmgrey {
			@apply border-black

		}

	}

	.accordion.accordion-item.accordion-item-body {
		> :first-child {
			@apply mt-15;

		}

		> :last-child {
			@apply mb-60;

		}

	}

}

.product-teaser-badge {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	
	background: #000000;
	
	text-transform: uppercase;
	
	color: white;
	
	width: 60px;
	height: 60px;
	
	border-radius: 50%;
	
	transform: rotate(-15deg);
	
	font-size: 12px;
	line-height: 1;
	
	text-align: center;
	
	display: flex;
	align-items: center;
	justify-content: center;
	
	font-weight: 600;
	
	&.bg-akurit-blue {
		background: #79b8df;
		
	}
	
	&.bg-akurit-gold {
		background: #c39f66;
		
	}
	
}
