@font-face{
	font-family:"Helvetica Now Text W01";
	font-style: normal;
	font-weight: 400;
	src:url("/_Resources/Static/Packages/Wysiwyg.Sievert.Tubag/Fonts/5760841/46dd84ea-bb96-46da-973b-d7fcca46437e.woff2") format("woff2"),url("Fonts/5760841/0fb90058-c064-40ed-8109-d1fd8633caa2.woff") format("woff");

}

@font-face{
	font-family:"Helvetica Now Text W01";
	font-style: normal;
	font-weight: 600;
	src:url("/_Resources/Static/Packages/Wysiwyg.Sievert.Tubag/Fonts/5760861/39ae88e8-7d53-4395-8f9d-f7a0d4060ec4.woff2") format("woff2"),url("Fonts/5760861/0c4f3bb0-d605-4462-9603-9163a560a536.woff") format("woff");

}

@font-face{
	font-family:"Helvetica Now Text W01";
	font-style: normal;
	font-weight: 500;
	src:url("/_Resources/Static/Packages/Wysiwyg.Sievert.Tubag/Fonts/5761058/e62ce8f4-9983-4262-9030-3fdbebcebf39.woff2") format("woff2"),url("Fonts/5761058/0e8ae071-ceee-4b41-9d37-cbb6af5a9ca9.woff") format("woff");

}

h1, .h1 {
	@apply font-bold;
	@apply leading-tight;

	@apply text-3xl;

	@screen md {
		@apply text-5xl;

	}

	@screen lg {
        @apply text-6xl;

	}

}

/* headline below convex or concave header image */
.transparent-header h1 {
    margin-top: 0;
}

h2, .h2 {
	@apply font-bold;
	@apply leading-snug;

	@apply text-xl;

	@screen md {
		@apply text-2xl;

	}

	@screen lg {
		@apply text-4xl;

	}

}

.story-teaser-headline {
    padding-top: 0.5em;

    @apply w-10/12;

    @screen md {
        @apply w-9/12;

    }

    @screen lg {
        @apply w-full;

    }
	
	&, + .button {
		position: relative;
		z-index: 1;
		
	}
	
}

h3, .h3 {
	@apply font-bold;
	@apply leading-normal;

	@apply text-lg;

	@screen md {
		@apply text-xl;

	}

}

h4, .h4 {
	@apply font-bold;
	@apply leading-normal;

	@apply text-md;

	@screen md {
		@apply text-lg;

	}

}

.neos-backend .whitespace-no-wrap {
    position: relative;
    padding: 0 .25em;

    &:after {
        content: " ";
        position: absolute;
        @apply inset-0;

        border-left: 2px dotted;
        border-right: 2px dotted;
        border-bottom: 2px dotted;
        transform: translateY(.15em);

        opacity: .425;

        pointer-events: none;

    }
}

p.leading, .leading, .rich-text {
	a {
		text-decoration: underline;
		&:hover {
			@apply text-tubag-red;
		}
	}
}

p.leading, .leading {
	@apply text-lg;
	@apply leading-normal;

	@screen md {
		@apply text-xl;

	}
	/*
	br + br {
		display: none;

	}
	*/
}

p, .p, %copy-text {
	@apply font-normal;
	@apply leading-relaxed;

	@apply text-sm;

	@screen md {
		@apply text-md;

	}

}

h1 + .leading {
	margin-top: 1em;

	@apply text-lg;

    @screen md {
        @apply text-xl;

    }

}

/*
h1.sievert-logo-square {
	@screen md {
		width: 80%;

	}

}
*/

%link-style {
	@apply text-xs;
	@apply leading-loose;

	@screen md {
		@apply text-sm;

	}

}

.link {
	display: table;

	@extend %link-style;

	+ .link {
		@apply mt-15;

	}

}

p.info-text, .info-text {
	@apply font-medium;
	@apply text-2xs;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.whitespace-pre-line {
	white-space: nowrap;

	.neos-backend & {
		position: relative;
		padding: 0 .25em;

		&:after {
			content: " ";
			position: absolute;
			@apply inset-0;

			border-left: 2px dotted;
			border-right: 2px dotted;
			border-bottom: 2px dotted;
			transform: translateY(.15em);

			opacity: .425;

			pointer-events: none;

		}

	}

}

body {
	font-family: "Helvetica Now Text W01", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;

	color: #000000;

	@extend %copy-text;

}

.product-name-hyphenated {
	hyphens: auto;
	-webkit-hyphens: auto;

	hyphenate-limit-chars: 10 5 5;
	hyphenate-limit-lines: 2;
	hyphenate-limit-last: always;
	hyphenate-limit-zone: 8%;

}
