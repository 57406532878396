%progress-bar {
	width: 100%;

	display: flex;
	justify-content: center;
	
}

%progress-bar-item {
	pointer-events: auto;
	
	cursor: pointer;
	
	display: inline-block;
	
	width: 30px;
	height: 30px;
	
	position: relative;
	
	&:after {
		content: " ";
		display: block;
		
		position: absolute;
		top: 10px;
		left: 10px;
		
		width: 10px;
		height: 10px;
		
		border-radius: 50%;
		
		background: #ffffff;
		
	}
	
	opacity: .5;
	
	&:hover {
		opacity: .8;
		
	}
	
	&.active {
		opacity: 1;
		
	}
	
}

[data-class-name=ScrollableTable] {
	> .table-container {
		position: relative;
		
		@extend %scroll-bar-disabled;
		
		> table {
			margin-bottom: 1px;
			
		}
		
	}
	
	&.scrollable {
		position: relative;
		
		> .table-container {
			overflow: scroll;
			
		}
		
		&:before,
		&:after {
			@extend .shape;
			
			content: " ";
			position: absolute;
			z-index: 1;
			
			width: 50px;
			top: 0;
			height: 100%;
			
			opacity: 0;
			transition: opacity .25s;
			
		}
		
		&:before {
			left: 0;
			background-image: linear-gradient(to left, rgba(#ffffff, 0), rgba(#ffffff, 1));
			
		}
		
		&:after {
			right: 0;
			background-image: linear-gradient(to right, rgba(#ffffff, 0), rgba(#ffffff, 1));
			
		}
		
		&.scrollable-left:before {
			opacity: 1;
			
		}
		
		&.scrollable-right:after {
			opacity: 1;
			
		}
		
	}
	
	.scrollable-table-progress-bar {
		@extend %progress-bar;
		
		@apply mt-45;
		
		&-item {
			@extend %progress-bar-item;
			
			&:after {
				background: #000000;
				
			}
			
			opacity: .15;
			
			&:hover {
				opacity: .48;
				
			}
			
			&.active {
				opacity: .48;
				
			}
			
		}
		
	}
	
	&:not(.scrollable) {
		.scrollable-table-progress-bar {
			display: none;
			
		}
		
	}
	
}

.numbered-navigation {
	display: flex;
	flex-wrap: wrap;
	
	margin: -15px;
	margin-bottom: 60px - 15px;
	
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		
		width: 50px;
		height: 50px;
		
		@apply bg-black;
		@apply text-white;
		
		border-radius: 50%;
		
		margin: 10px;
		
		font-size: 24px;
		line-height: 1;
		
		@screen xl {
			margin: 15px;
			
			width: 60px;
			height: 60px;
			
			font-size: 28px;
			
		}
		
		&.active,
		&:hover {
			@apply bg-green;
			
		}
		
	}
	
	.column + & {
		margin-top: 45px;
		
		@screen sm {
			margin-top: 60px;
			
		}
		
	}
	
}

.step-by-step-container,
.system-page {
	.teaser-gallery {
		.gallery__navigation__arrow {
			background-color: #aaaaaa;
			border-radius: 50%;
			
			&--left {
				background-image: url("#{$img-root}/ui/gallery-arrow-neg-left.svg");
				
			}
			
			&--right {
				background-image: url("#{$img-root}/ui/gallery-arrow-neg-right.svg");
				
			}
			
		}
		
	}
	
}

.step-by-step-container {
	.step-by-step-content {
		position: relative;
		
		> .row {
			transform-origin: 50% 25%;
			
			margin-top: 0;
			margin-bottom: 0;
			
			&:not(:first-child) {
				display: none;
				
			}
			
			left: 0;
			top: 0;
			width: 100%;
			
		}
		
		@screen md {
			transform: translateX(-45px);
			
		}
		@screen lg {
			transform: none;
			
		}
		
		@screen md {
			.numbered-navigation {
				display: none;
				
			}
			
		}
		
		.teaser-gallery {
			.gallery__navigation__arrow {
				width: 50px;
				height: 50px;
				
				@screen xl {
					width: 60px;
					height: 60px;
					
				}
				
			}
			
		}
		
	}
	
}


.system-image {
	position: relative;
	
	@screen lg {
		margin-left: 6%;
		
	}
	
	&-label {
		position: absolute;
		
		top: 10%;
		left: 24%;
		
		width: 38px;
		height: 38px;
		
		border-radius: 50%;
		
		display: flex;
		align-items: center;
		justify-content: center;
		
	}
	
}

