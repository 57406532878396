%footer-link-hover-style {
	&:hover {
        @apply underline;
		text-underline-offset: .3em;
		text-decoration-thickness: 2px;

	}

	@screen sm {
		padding-bottom: 2px;
		border-bottom: 2px solid transparent;
		margin-bottom: -2px;

		&:hover {
			text-decoration: none;
			border-color: currentColor;

		}

	}

}

footer.section {
	display: flex;
	align-items: center;

	overflow: hidden;

	> .container {
		margin-top: 80px;
		margin-bottom: 48px;

		@screen sm {
			margin-top: 100px;
			margin-bottom: 60px;
		}

		@screen md {
			margin-top: 120px;
			margin-bottom: 100px;

		}

		@screen xl {
			position: relative;
			z-index: 1;

			&:before {
				content: " ";
				display: block;

				position: absolute;
				z-index: -1;

				right: 40%;
				left: -50vw;
				top: -120px;
				bottom: -100px;

			}

		}

		> .row {
			justify-content: space-between;
			align-items: center;

			flex-direction: column;

			@screen xl {
				flex-direction: row;
				align-items: stretch;

			}

			> .column {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
                //align-items: center;
			}

		}

		.footer-contact-column {
			@screen xl {
				order: 2;

			}

			.footer-contact {
				margin-right: auto;

				h2 {
					@apply font-medium;

					margin-top: -.1em;
					margin-bottom: .725em;
					width: 100%;
					max-width: 100%;

				}

			}

			.footer-endorsement {
				width: 100%;
				max-width: 520px;

				margin-top: 58px;

				@screen sm {
					//margin-top: 58px;
                    //margin-left: unset;

				}

				@screen md {
					margin-top: 68px;
					//margin-left: auto;

				}

				/*@screen lg {
					margin-top: 68px;

				}

				@screen xl {
					margin-top: 68px;

				}*/

				> div {
					@media (max-width: 560px) {
						max-width: 335px;
						//margin-left: auto;

					}

					&:last-child {
						//margin-bottom: -1rem;

						display: flex;
						align-items: flex-end;
						//justify-content: space-between;

					}

					&:first-child {
						display: flex;
						align-items: flex-start;
						justify-content: space-between;

                        border-bottom: 1px solid #000;
                        margin-bottom: 30px;
                        padding-bottom: 24px;

						.footer-endorsement-mobile-separator {
							display: none;

						}

						@media (max-width: 560px) {
							flex-wrap: wrap;

							.footer-endorsement-mobile-separator {
								display: block;
								width: 100%;
								margin-bottom: -10px;

							}

						}

					}

					> * {
						height: 23px;
						background: 50%/contain no-repeat;

					}


					.footer-endorsement {
						&-marken {
                            display: none;
							//width: 88px;
							//height: 9px;

							//background-image: url("#{$img-root}/footer/endorsement-marken.svg");

						}

						&-sievert {
							width: 235px;
							height: 23px;
							margin-bottom: -1px;

							background-image: url("#{$img-root}/footer/endorsement-sievert-extended-de.svg");

						}

						&-quick-mix {
							&, &-active {
								width: 88px;

							}
							background-image: url("#{$img-root}/footer/endorsement-quick-mix.svg");

							&-active {
								background-image: url("#{$img-root}/footer/endorsement-quick-mix-active.svg");

							}

						}

						&-akurit {
							&, &-active {
								width: 63px;

							}
							background-image: url("#{$img-root}/footer/endorsement-akurit.svg");

							&-active {
								background-image: url("#{$img-root}/footer/endorsement-akurit-active.svg");
							}

						}

						&-tubag {
							&, &-active {
								width: 63px;

							}
							background-image: url("#{$img-root}/footer/endorsement-tubag.svg");

							&-active {
								background-image: url("#{$img-root}/footer/endorsement-tubag-active.svg");

							}

						}

						&-strasser {
							&, &-active {
								width: 80px;

							}
							background-image: url("#{$img-root}/footer/endorsement-strasser.svg");

							&-active {
								background-image: url("#{$img-root}/footer/endorsement-strasser-active.svg");

							}

						}

						&-hahne {
							&, &-active {
								width: 66px;

							}
							background-image: url("#{$img-root}/footer/endorsement-hahne.svg");

							&-active {
								background-image: url("#{$img-root}/footer/endorsement-hahne-active.svg");

							}

						}

						&-sievert-logistik {
							&, &-active {
								width: 59px;

							}
							background-image: url("#{$img-root}/footer/endorsement-sievert-logistik.svg");

							&-active {
								background-image: url("#{$img-root}/footer/endorsement-sievert-logistik-active.svg");

							}

						}

					}

				}

                .footer-endorsement-brands-label {
                     display: block!important;
                 }

			}

		}

	}

	.footer-links-column {
		align-items: initial !important;

		position: relative;

		margin-top: 80px;

		@screen sm {
			margin-top: 115px;

		}

		@screen md {
			margin-top: 140px;

		}

		&:before {
			content: " ";
			display: block;

			position: absolute;
			z-index: -1;

			left: -50vw;
			bottom: -80px;


			top: -40px;
			bottom: -50px;
			right: -125px;

			@screen sm {
				top: -50px;
				right: -100px;

			}

			@screen md {
				top: -70px;
				right: 18px;
				bottom: -80px;

			}

			@screen lg {
				right: 10%;

			}

			@screen xl {
				top: -30px;
				right: 10%;

			}

		}

		@screen xl {
			margin-top: 0;

			&:before {
				content: none;

			}

		}

		.row {
			@media (min-width: 768px) {
				@media (max-width: 1023px) {
					.md\:w-3\/12 {
						width: 33.333%;

					}

				}

			}

		}

		.footer-links {
			display: block;

			@extend %link-style;

			a {
				@extend %footer-link-hover-style;

			}

			> * {
				display: table;

				+ * {
					margin-top: 15px;

				}

			}

		}

		@media (max-width: 560px) {
			margin-bottom: 0;

			.row {
				display: block;

			}

			.footer-links {
				display: block;
				padding: 0;
				width: 100%;

				> * {
					display: block;
					margin-top: 0;

					width: 50%;
					float: left;
					padding-left: 10px;

					padding-top: 15px;

				}

			}

			&:after {
				content: " ";
				display: table;
				clear: both;

			}

		}

		.footer-legal-social {
			margin-top: 15px;

			@screen md {
				margin-top: 60px;

			}

			@screen xl {
				margin-top: 0;

			}

			> .column {
				align-items: center;

				&:first-child {
					order: 2;

				}

				@screen lg {
					align-items: flex-start;

					&:first-child {
						order: 0;

					}

				}

			}

			.footer-legal-links {

                @screen xl {
                    padding-bottom: 28px;
                }

				a {
					@extend %footer-link-hover-style;

				}

				> * {
					@extend %link-style;

					margin-left: 18px;
					margin-right: 18px;

					@screen sm {
						margin-left: 0;
						margin-right: 0;

						+ * {
							margin-left: 30px;
							margin-right: 0;

						}

					}

				}

				span {
					order: 10;
					width: 100%;
					margin-top: 15px;

				}

				margin-top: 20px;

				@screen sm {
					span {
						order: 0;
						width: auto;
						margin-top: 0;

					}

				}

				@screen md {
					margin-top: 0;

				}

				@media (max-width: 560px) {
					> * {
						display: block;
						width: 50%;
						float: left;

						margin-left: 0;
						margin-right: 0;

						margin-top: 15px;

					}

					&:after {
						content: " ";
						display: table;
						clear: both;

					}

				}

			}

			.footer-social-links {
				display: flex;

				margin-top: 50px;

				@screen sm {
					margin-top: 24px;

				}

				.footer-social-link {
					display: block;
					width: 22px;
					height: 22px;

					+ .footer-social-link {
						margin-left: 30px;

						@screen sm {
							margin-left: 45px;

						}

					}

					background: 50% no-repeat;

					&-linked-in {
						width: 23px;
						margin-right: -1px;

						background-image: url("#{$img-root}/footer/social-icon-linked-in-black.svg");

					}

					&-xing {
						background-image: url("#{$img-root}/footer/social-icon-xing-black.svg");

					}

					&-facebook {
						background-image: url("#{$img-root}/footer/social-icon-facebook-black.svg");

					}

					&-instagram {
						background-image: url("#{$img-root}/footer/social-icon-instagram-black.svg");

					}

					&-you-tube {
						background-image: url("#{$img-root}/footer/social-icon-you-tube-black.svg");

					}

				}

			}

		}

	}

}

footer.campaign.section > .container:before,
footer.campaign.section > .gallery__caption-footer:before,
footer.campaign.section .footer-links-column:before {
    content: none !important;
}
