/*
	Quick-mix Navigation Overrides
*/

.header-logo {
	display: block;
	transform: translateZ(0);

	background: url("#{$img-root-quick-mix}/header/logo-quick-mix.svg") 0 50%/contain no-repeat;
	
	&-negative {
		background-image: url("#{$img-root-quick-mix}/header/logo-quick-mix-negative.svg");
		
	}
	
	margin-top: 25px + 2px;

	width: 142px;
	height: 45px;

	@screen lg {
		margin-top: 25px + 4px;

		width: 142px;
		height: 45px;

	}

}
