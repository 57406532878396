.page-tool-icon {
	&.page-tool-search {
		background-image: url("#{$img-root}/header/icon-search-black.svg");
		
	}
	
	&.page-tool-menu {
		background-image: url("#{$img-root}/header/icon-menu-black.svg");
		
	}
	
	&.page-tool-close {
		background-image: url("#{$img-root}/header/icon-close-black.svg");
		
	}
	
}

.search-field {
	.page-tool-close {
		background-image: url("#{$img-root}/header/icon-close-black.svg");
		
	}
	
}

.page-tools {
	.page-tool-menu {
		background-image: url("#{$img-root}/header/icon-menu-black.svg");

		header.page-header.expanded & {
			background-image: url("#{$img-root}/header/icon-close-black.svg");
			
		}
		
	}
	
}

.page-navigation-level-container {
	.page-navigation-back-link {
		&:before {
			background-image: url("#{$img-root}/header/navigation-arrow-back-black.svg");
			
		}
		
	}
	
}

ul.page-navigation-level {
	li {
		&.page-navigation-item--with-sub-items > a {
			&:after {
				background-image: url("#{$img-root}/header/navigation-arrow-right-black.svg");
				
			}
			
		}
		
		&.current > a {
			&:after {
				background-image: url("#{$img-root}/header/navigation-arrow-right-active-black.svg")
				
			}
			
		}
		
	}
	
	&.page-navigation-level-one {
		> li {
			&.current > a {
				&:after {
					background-image: url("#{$img-root}/header/navigation-arrow-right-active-black.svg")
					
				}
				
			}
			
			+ li {
				margin-top: 16px;
				
			}
			
		}
		
	}
	
}

.page-header ul.page-navigation-level {
	li {
		> a {
			padding-bottom: 0px;
			
			@screen md {
				padding-bottom: 2px;
				
			}
			
		}
		
	}
	
}
