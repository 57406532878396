.background-shape-container {
	position: absolute;
	z-index: -1;

	width: 100%;

	main & {
		margin-top: 0;

	}

	body.transparent-header & {
		margin-top: 95px;

		@screen lg {
			margin-top: 100px;

		}

	}

	.background-shape {
		position: relative;

		margin-left: auto;
		margin-right: auto;

		&:after {
			content: " ";
			display: block;

			background: 50%/contain no-repeat;

		}

	}

	&-header {
		//position: relative;

        height: 100vw;

        @screen sm {
            height: 67.8571428571429vw;

        }

        @screen md {
            height: 50vw;

        }

        //margin-bottom: calc(10vw + 67px);

        @screen sm {
            //margin-bottom: calc(10vw + 60px);

        }

        @screen lg {
            margin-bottom: 20px;
        }

        @screen xl {
            //margin-bottom: calc(9% + 60px);
            //margin-bottom: 210px;
            margin-bottom: 80px;
        }

        .background-shape {
            position: absolute;
            z-index: 16;

        }

		&-form {

            &:has([data-class-name=ImageSequencer]) {
                position: relative;
            }

            /* try: press release */
            /*+ .container {

                .justify-center.row {
                    margin-top: 60px;

                    @screen md {
                        margin-top: 80px;
                    }

                    @screen lg {
                        margin-top: 100px;
                    }
                }
            }*/

            margin-top: 99px;
            max-height: 920px;


			//margin-bottom: calc(10vw + 67px);
            //margin-bottom: 60px;

			/*@screen sm {
				margin-bottom: calc(10vw + 60px);
			}

			@screen 2xl {
				margin-bottom: calc(9% + 60px);
			}*/

			.background-shape {
				/*position: absolute;
				z-index: 16;*/

				background: url("#{$img-root-quick-mix}/background-shapes/quick-mix_header-bg-form.svg") 50% -60px/100% no-repeat;

				width: 100%;
				height: 150%;

                mix-blend-mode: multiply;

				@screen sm {
                    height: 170%;
                    left: -10%;

				}

				@screen md {
                    background-position: 50% -100px;
                    height: 220%;
				}

				@screen lg {
                    height: 220%;
                    left: -15%;
				}
			}

            [data-class-name=ImageSequencer] {
                max-height: 920px;
            }

            &.mirrored {

                .background-shape {
                    /*background-position: 50% -100px;
                    background-size: 100%;*/

                    -moz-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    filter: FlipH;
                    -ms-filter: "FlipH";

                    @screen sm {
                        left: unset;
                        right: -10%;

                    }

                    @screen lg {
                        right: -15%;
                    }
                }
            }
		}

		main > & > .background-shape {
			+ * {
				margin-top: 0;

			}
		}
	}

	[data-class-name=ImageSequencer] {
		position: absolute;
		width: 100%;
		height: 100%;

		> div {
			position: static !important;

		}

	}

	figcaption {
		display: none;

	}

	img {
		position: absolute;
		z-index: -1;

		@apply inset-0;
		height: 100%;

		object-fit: cover;
		object-position: 50% 50%;

	}

}

.pr-background-shape-shared-content {
    margin-top: 0px !important;
    padding-bottom: 85px !important;
}


.pr-background-shape {
    position: relative;
    overflow: hidden;

    .background-shape-image > *:not(.class) {
        &:after {
            content: " ";
            position: absolute;
            z-index: 1;

            @apply inset-0;
            right: -100vw;
            @apply bg-tubag-red;

            transform-origin: 100% 100%;
            transform: skew(-23deg);

            left: 60vw;

        }

		display: none;

        + * {
            display: block;

        }

    }

    &.pr-background-shape-image {
        .background-shape-image {
            &.background-shape-image--left > *:after {
                left: -100vw;
                right: 90vw;
            }
            & > *:after {
                left: 70vw;
            }
            &.ref-background-shape > *:after {
                left: 50vw;
            }

        }

		figure {
			position: relative;
			z-index: 2;

			margin-top: 50px;
			margin-bottom: 50px;

		}

		margin-bottom: 45px;

		@screen md {
			figure {
				margin-top: 65px;
				margin-bottom: 65px;

			}

		}
		@screen xl {
			figure {
				margin-top: 90px;
				margin-bottom: 0;

			}

			margin-bottom: 90px;

			.background-shape-image > *:after {
				bottom: 125px;

			}

		}

    }

    .background-shape-image {
        &.background-shape-image--left > *:after {
            left: -100vw;
            right: 80vw;
        }
        &.background-shape-image--gray > *:after {
            @apply bg-warmgray;
        }
        &.background-shape-image--left.background-shape-image--gray > *:after {
            transform-origin: 100% 0%;
            right: 50vw;
        }
        &.background-shape-image--gray > *:after {
            left: 50vw;
        }

    }

    &.pr-background-shape-image-empty {
		overflow: visible;
		height: 0;
		z-index: -1;

		> div > div {
			position: relative;
			overflow: hidden;

			height: 100vw;

			@media (min-width: 379px) {
				height: 379px;

			}
			@screen md {
				height: 480px;

			}
			@screen lg {
				height: 560px;

			}
			@screen xl {
				height: 708px;

			}

		}

		+ .container {
			margin-top: 60px;

			@screen md {
				margin-top: 90px;

			}
			@screen xl {
				margin-top: 150px;

			}

		}

    }

}
