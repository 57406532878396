/**
 * Download List
 *
 */

.download-list {
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
	
	@extend %teaser-with-squared-content;
	
	> div:first-child + div {
		margin-top: -22px;
		padding-top: 32px;
		
		@screen xl {
			margin-top: -70px;
			padding-top: 80px;
			
		}
		
	}
	
	.text-link {
		&:after {
			content: none;

		}

	}
	&:hover {
		.text-link,
        .flex.items-center {
			@apply text-primary;

		}

	}

    span + svg {
        flex: 1 0 auto;
        align-self: flex-end;
        margin-bottom: .35em
        
    }
    
    img {
        border: #C7C7C7 1px solid;
    }

    .fixed-height {
        aspect-ratio: 1/1;
        
    }
	
}

.teaser-gallery .gallery__slide--teaser__product .download-list img {
    width: inherit;
    margin-left: auto;
    margin-right: auto;
}

.product-download-vertical {
    width: 66.666667% !important;

    @screen md {
        width: 75% !important;
    }
}

.teaser-gallery .gallery__slide--teaser__product .download-list .horizontal {
    width: 100% !important;

    @screen sm {
        width: inherit;
    }
}
